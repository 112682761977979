


























/* eslint-disable quote-props */
import { defineComponent, onMounted, ref } from '@vue/composition-api'
import gsap from 'gsap'
import { motionOK } from '@/inc/utils/motionOK'
import WebP from '@/components/WebP.vue'
import ScrollTrigger from '@/inc/vendor/gsap/ScrollTrigger'

const INITIAL_SCALE = 1.2
const FINAL_SCALE = 1.1
const PARALLAX_AMOUNT = 15

export default defineComponent({
  name: 'ZoomOutImg',
  components: { WebP },
  props: {
    picture: {
      type: Object,
    },
    sets: {
      type: Array,
      default: () => [1920],
    },
    autoplay: {
      default: false,
      type: Boolean,
    },
    className: {
      default: '',
      type: String,
      required: false,
    },
  },
  setup(props) {
    const rootRef = ref()
    const TL = ref()

    onMounted(() => {
      ScrollTrigger.create({
        trigger: rootRef.value,
        start: 'top bottom',
        end: 'bottom top',
        onUpdate: self => {
          const progress = (self.progress - 0.5) * 2
          rootRef.value.style.setProperty(
            '--translate',
            `${progress * PARALLAX_AMOUNT}%`
          )
        },
      })

      if (motionOK()) {
        rootRef.value.style.setProperty('--scale', INITIAL_SCALE)
      }

      TL.value = gsap
        .timeline({
          paused: !props.autoplay,
          defaults: {
            ease: 'power4.out',
            duration: 5,
          },
        })
        .addLabel('start')
        .to(
          rootRef.value,
          {
            '--scale': FINAL_SCALE,
          },
          'start'
        )
        .to(
          rootRef.value,
          {
            '--clip': '-1%',
            duration: 2,
          },
          'start'
        )
    })

    const animateIn = () => {
      if (TL.value) {
        TL.value.resume()
      }
    }

    const getTween = () => TL.value

    return {
      rootRef,
      animateIn,
      getTween,
    }
  },
})
