


























import { defineComponent, ref } from '@vue/composition-api'
import NewsCard from '@/components/NewsCard.vue'
import LineSplitter from '@/components/animation/LineSplitter.vue'
import { motionOK } from '@/inc/utils/motionOK'
import gsap from 'gsap'

export default defineComponent({
  name: 'featured-news',
  components: {
    NewsCard,
    LineSplitter,
  },
  props: {
    content: {
      required: true,
      type: Object,
    },
  },
  setup() {
    const titleRef = ref()
    const newsRef = ref()

    const animateIn = () => {
      if (motionOK()) {
        const TL = gsap.timeline({
          defaults: {
            duration: 1,
            ease: 'power4.out',
          },
        })

        if (titleRef.value) {
          TL.add(titleRef.value.getTween(), 'start')
        }

        newsRef.value.forEach((news, i) => {
          TL.add(news.getTween(), `start+=${0.25 * i}`)
        })
      }
    }

    return {
      titleRef,
      newsRef,
      animateIn,
    }
  },
})
