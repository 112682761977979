const parseTimestamp = timestamp => {
  const date = new Date(timestamp)
  const dd = String(date.getDate()).padStart(2, '0')
  const mm = String(date.getMonth() + 1).padStart(2, '0')
  const yyyy = date.getFullYear()

  return { dd, mm, yyyy }
}

export const formatPubDate = timestamp => {
  const { dd, mm, yyyy } = parseTimestamp(timestamp)

  const yy = yyyy.toString().substr(-2)

  return `${dd}.${mm}.${yy}`
}

export const formatPubDateUS = timestamp => {
  const { dd, mm, yyyy } = parseTimestamp(timestamp)

  return `${yyyy}-${mm}-${dd}`
}
