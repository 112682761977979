<template>
  <svg
    class="magic"
    viewBox="0 0 82 35"
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    ref="rootRef"
  >
    <line
      transform="rotate(.09 39.47 17.4)"
      stroke-width="2"
      y2="17.4"
      x2="78.89"
      y1="17.4"
      x1=".05"
      ref="bodyRef"
    />
    <line
      transform="rotate(44.64 71.79 9.42)"
      stroke-width="2"
      y2="9.42"
      x2="84.1"
      y1="9.42"
      x1="59.48"
      ref="headTopRef"
    />
    <line
      transform="rotate(-44.58 71.79 25.5)"
      stroke-width="2"
      y2="25.5"
      x2="84.1"
      y1="25.5"
      x1="59.48"
      ref="headBottomRef"
    />
  </svg>
</template>

<style lang="scss" scoped>
svg {
  stroke: currentColor;
}
</style>

<script>
import { defineComponent, onMounted, ref } from '@vue/composition-api'
import gsap from 'gsap'
import { motionOK } from '@/inc/utils/motionOK'

export default defineComponent({
  setup() {
    const bodyRef = ref()
    const headTopRef = ref()
    const headBottomRef = ref()

    onMounted(() => {
      // Use document.documentElement.clientWidth > 480 because otherwise
      // svg is display none and trying to compute length of non rendered element will break.
      // Turns out that using simply innerWidth does not uses the same math as the css media query.
      // IE:  document.documentElement.clientWidth == 360 && innerWidth == 640
      if (motionOK() && document.documentElement.clientWidth > 480) {
        gsap.set([bodyRef.value, headTopRef.value, headBottomRef.value], {
          strokeDashoffset: (_i, el) => Math.ceil(el.getTotalLength()),
          strokeDasharray: (_i, el) => Math.ceil(el.getTotalLength()),
        })
      }
    })

    const getTween = () =>
      gsap
        .timeline({
          defaults: {
            duration: 1,
            ease: 'power4.out',
          },
        })
        .to(
          bodyRef.value,
          {
            strokeDashoffset: 0,
          },
          0
        )
        .to(
          headTopRef.value,
          {
            strokeDashoffset: 0,
          },
          '>-0.75'
        )
        .to(
          headBottomRef.value,
          {
            strokeDashoffset: 0,
          },
          '>-0.75'
        )

    return {
      bodyRef,
      headTopRef,
      headBottomRef,
      getTween,
    }
  },
})
</script>
