<template>
  <div
    class="clip o-h"
    ref="rootRef"
    v-enter-viewport
    @enterViewport="animateIn"
    v-if="video && video.src"
  >
    <div class="video-poster-container" v-if="!playVideo">
      <video
        v-if="videoMobile && videoMobile.src"
        autoplay
        muted
        playsInline
        loop="true"
        :poster="`${videoMobile.poster.src}`"
        :src="videoMobile.src"
      >
        <source :type="'video/mp4'" />
      </video>
      <img
        v-else-if="videoMobile && videoMobile.poster"
        :src="`${videoMobile.poster.src}`"
        alt=""
      />
      <button class="video-play" @click="play">
        <svg
          class="video-play__svg"
          width="72"
          height="72"
          viewBox="0 0 72 72"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clip-path="url(#clip0_2119_6281)">
            <path d="M52 36L28 20L28 52L52 36Z" fill="white" />
          </g>
          <rect
            x="1.5"
            y="1.5"
            width="69"
            height="69"
            rx="34.5"
            stroke="white"
            stroke-width="3"
          />
          <defs>
            <clipPath id="clip0_2119_6281">
              <rect
                width="24"
                height="32"
                fill="white"
                transform="translate(28 20)"
              />
            </clipPath>
          </defs>
        </svg>
        <span>{{ $t('playVideo') }}</span>
      </button>
    </div>
    <video
      controls="true"
      loop="true"
      v-else
      :poster="`${video.poster.src}`"
      :src="video.src"
    />
  </div>
</template>

<style lang="scss" scoped>
.clip {
  --clip: 99%;

  clip-path: inset(-1% var(--clip) -1% -1%);
}

video {
  width: 100%;
}

.video-poster-container {
  position: relative;

  img {
    width: 100%;
    transform: scale(var(--scale)) translateY(var(--translate));
  }
}

.video-play {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: transparent;
  border-width: 0;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: $c-white;
  font-size: 4rem;
  font-weight: 600;
  text-align: center;

  // prettier-ignore
  @include fluid(font-size, (xxs: 20px, xl: 40px));
}

.video-play__svg {
  @include fluid(
    width,
    (
      xxs: 40px,
      xl: 72px,
    )
  );
  @include fluid(
    height,
    (
      xxs: 40px,
      xl: 72px,
    )
  );

  margin-bottom: 1.4rem;
}
</style>

<script>
/* eslint-disable quote-props */
import {
  defineComponent,
  onMounted,
  onUnmounted,
  ref,
} from '@vue/composition-api'
import gsap from 'gsap'
import { motionOK } from '@/inc/utils/motionOK'
import ScrollTrigger from '@/inc/vendor/gsap/ScrollTrigger'

const INITIAL_SCALE = 1.2
const FINAL_SCALE = 1.1
const PARALLAX_AMOUNT = 15

export default defineComponent({
  name: 'ZoomOutVideo',
  props: {
    video: {
      type: Object,
    },
    videoMobile: {
      type: Object,
    },
  },
  setup(props) {
    const rootRef = ref()
    const TL = ref()
    const playVideo = ref(false)
    const isDesktop = ref(false)

    onMounted(() => {
      ScrollTrigger.create({
        trigger: rootRef.value,
        start: 'top bottom',
        end: 'bottom top',
        onUpdate: self => {
          const progress = (self.progress - 0.5) * 2
          rootRef.value.style.setProperty(
            '--translate',
            `${progress * PARALLAX_AMOUNT}%`
          )
        },
      })

      if (motionOK()) {
        rootRef.value.style.setProperty('--scale', INITIAL_SCALE)
      }

      TL.value = gsap
        .timeline({
          paused: !props.autoplay,
          defaults: {
            ease: 'power4.out',
            duration: 5,
          },
        })
        .addLabel('start')
        .to(
          rootRef.value,
          {
            '--scale': FINAL_SCALE,
          },
          'start'
        )
        .to(
          rootRef.value,
          {
            '--clip': '-1%',
            duration: 2,
          },
          'start'
        )

      window.addEventListener('resize', onResize)
      onResize()
    })

    onUnmounted(() => {
      window.removeEventListener('resize', onResize)
    })

    const animateIn = () => {
      if (TL.value) {
        TL.value.resume()
      }
    }

    const getTween = () => TL.value

    const onResize = () => {
      isDesktop.value = document.documentElement.clientWidth >= 960
    }

    const play = () => {
      playVideo.value = true
      window.setTimeout(() => {
        const videoEl = rootRef.value.querySelector('video')
        videoEl.play()
      }, 1)
    }

    return {
      rootRef,
      animateIn,
      getTween,
      playVideo,
      play,
      isDesktop,
      domain: process.env.VUE_APP_API_DOMAIN,
    }
  },
})
</script>
