


















import { defineComponent, onMounted, ref } from '@vue/composition-api'
import gsap from 'gsap'
import { motionOK } from '@/inc/utils/motionOK'

export default defineComponent({
  name: 'Strech',
  props: {
    type: {
      required: false,
      type: String,
      default: 'div',
    },
  },
  setup() {
    const rootRef = ref()

    onMounted(() => {
      if (motionOK()) {
        gsap.set(rootRef.value, {
          opacity: 0,
        })
      }
    })

    const getTween = () =>
      gsap.to(rootRef.value, {
        opacity: 1,
        duration: 1,
        ease: 'power2.out',
        autoRound: false,
        onStart: () => {
          // Animating scaleY with GSAP is very choppy vut using CSS runs smoothly...
          gsap.set(rootRef.value, {
            scaleY: 1,
          })
        },
      })

    return {
      rootRef,
      getTween,
    }
  },
})
