



import { defineComponent, onMounted, ref } from '@vue/composition-api'
import gsap from 'gsap'
import { motionOK } from '@/inc/utils/motionOK'

export default defineComponent({
  name: 'HorizontalLine',
  setup() {
    const rootRef = ref()

    onMounted(() => {
      if (motionOK()) {
        gsap.set(rootRef.value, {
          scaleX: 0,
        })
      }
    })

    const getTween = () =>
      gsap.to(rootRef.value, {
        scaleX: 1,
        ease: 'power4.out',
        duration: 2,
      })

    return {
      rootRef,
      getTween,
    }
  },
})
