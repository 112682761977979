<template>
  <router-link :to="news.link.url">
    <HorizontalLine class="mb-xs" ref="hrRef" />

    <LineSplitter
      v-if="news.publicationDate"
      :content="formatPubDate(news.publicationDate)"
      ref="dateRef"
      class="date mb-s"
      :type="'time'"
      :datetime="formatPubDateUS(news.publicationDate)"
    />

    <!-- TODO: CHECK HEADING -->
    <LineSplitter
      v-if="news.title"
      :content="news.title"
      ref="titleRef"
      class="news-listing__item__title mb-xxl"
      :type="'h3'"
    />
  </router-link>
</template>

<script>
import { defineComponent, ref } from '@vue/composition-api'
import { formatPubDate, formatPubDateUS } from '@/inc/utils/date'
import LineSplitter from '@/components/animation/LineSplitter.vue'
import HorizontalLine from '@/components/animation/HorizontalLine.vue'
import gsap from 'gsap'

export default defineComponent({
  props: {
    news: {
      required: true,
      type: Object,
    },
  },
  components: {
    HorizontalLine,
    LineSplitter,
  },
  setup() {
    const hrRef = ref()
    const dateRef = ref()
    const titleRef = ref()

    const getTween = () =>
      gsap
        .timeline()
        .add(hrRef.value.getTween(), 'start')
        .add(dateRef.value.getTween(), 'start+=0.25')
        .add(titleRef.value.getTween(), 'start+=0.5')

    return {
      formatPubDate,
      formatPubDateUS,
      getTween,
      hrRef,
      dateRef,
      titleRef,
    }
  },
})
</script>
