

















































































































































import { defineComponent, ref } from '@vue/composition-api'
import LineSplitter from '@/components/animation/LineSplitter.vue'
import HorizontalLine from '@/components/animation/HorizontalLine.vue'
import Arrow from '@/components/Arrow.vue'
import { motionOK } from '@/inc/utils/motionOK'
import gsap from 'gsap'

export default defineComponent({
  name: 'big-cta',
  components: { LineSplitter, HorizontalLine, Arrow },
  props: {
    cta: {
      required: false,
      type: Object,
      default: () => ({}),
    },
  },
  setup() {
    const hrRef = ref()
    const hr2Ref = ref()
    const titleRef = ref()
    const title2Ref = ref()
    const subtitleRef = ref()
    const subtitle2Ref = ref()
    const arrowRef = ref()
    const arrow2Ref = ref()

    const animateIn = () => {
      if (motionOK()) {
        const TL = gsap.timeline({
          defaults: {
            duration: 1,
            ease: 'power4.out',
          },
        })

        TL.add(hrRef.value.getTween(), 'start')
        TL.add(hr2Ref.value.getTween(), 'start')

        if (subtitleRef.value) {
          TL.add(subtitleRef.value.getTween(), 'start+=0.1')
        }
        if (subtitle2Ref.value) {
          TL.add(subtitle2Ref.value.getTween(), 'start+=0.1')
        }

        if (titleRef.value) {
          TL.add(titleRef.value.getTween(), 'start+=0.2')
        }
        if (title2Ref.value) {
          TL.add(title2Ref.value.getTween(), 'start+=0.2')
        }

        if (arrowRef.value) {
          TL.add(arrowRef.value.getTween(), 'start+=0.75')
        }
        if (arrow2Ref.value) {
          TL.add(arrow2Ref.value.getTween(), 'start+=0.75')
        }
      }
    }

    return {
      animateIn,
      hrRef,
      hr2Ref,
      titleRef,
      title2Ref,
      subtitleRef,
      subtitle2Ref,
      arrowRef,
      arrow2Ref,
    }
  },
})
