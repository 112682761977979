















































































import { defineComponent, ref } from '@vue/composition-api'
import LineSplitter from '@/components/animation/LineSplitter.vue'
import ZoomOutImg from '@/components/animation/ZoomOutImg.vue'
import ZoomOutVideo from '@/components/animation/ZoomOutVideo.vue'
import Strech from '@/components/animation/Strech.vue'
import { motionOK } from '@/inc/utils/motionOK'
import gsap from 'gsap'

export default defineComponent({
  name: 'single-wrapper',
  components: { LineSplitter, ZoomOutImg, ZoomOutVideo, Strech },
  props: {
    title: {
      required: false,
      type: String,
    },
    subtitle: {
      required: false,
      type: String,
    },
    picture: {
      required: false,
      type: Object,
    },
    content: {
      required: false,
      type: String,
    },
    video: {
      required: false,
      type: Object,
    },
    videoMobile: {
      required: false,
      type: Object,
    },
  },
  setup() {
    const titleRef = ref()
    const subtitleRef = ref()
    const visualRef = ref()
    const wysiwygRef = ref()

    const animateIn = () => {
      if (motionOK()) {
        const TL = gsap.timeline({
          defaults: {
            duration: 1,
            ease: 'power4.out',
          },
        })

        if (titleRef.value) {
          TL.add(titleRef.value.getTween(), 'start')
        }

        if (subtitleRef.value) {
          TL.add(subtitleRef.value.getTween(), 'start+=0.1')
        }

        if (visualRef.value) {
          TL.add(visualRef.value.getTween().resume(), 'start+=0.25')
        }
      }
    }

    const wysiwygAnimateIn = () => {
      if (motionOK()) {
        wysiwygRef.value.getTween()
      }
    }

    return {
      animateIn,
      titleRef,
      subtitleRef,
      visualRef,
      wysiwygAnimateIn,
      wysiwygRef,
    }
  },
})
